<template>
  <div class="student_notice">
    <div class="title_cont_box">
      <p class="title_text">学员须知</p>
      <p class="tips_info">欢迎各位学员参加劳模工匠人才创新研修班。此次研修班采取录播的形式上课，课前请各位学员阅读如下须知：
      </p>
    </div>

    <div class="notice_cont_box">
      <div class="cont_item cont1">
        <div class="num_title ">
          <span>一、学习时间</span>
        </div>
        <div class="cont_info_data">
          <p class="time_text">2024年9月—2024年10月</p>
          <p class="tips">请各位学员在规定的时间内完成学习。</p>
        </div>
      </div>
      <div class="cont_item cont2">
        <div class="num_title bgm2">
          <span>二、课程时间安排</span>
        </div>
        <div class="table_cont_list">
          <ul class="table_head">
            <li class="text_item1">课程</li>
            <!-- <li class="text_item2">授课时间</li> -->
            <li class="text_item3">授课方式</li>
            <li class="text_item4">授课人</li>
          </ul>
          <div class="cont_list">
            <ul>
                <div>
                    <li class="text_item1">新时代大力弘扬三种精神的重要意义与实践路径</li>
                    <!-- <li class="text_item2">3月30日(周六) 10:00-12:00</li> -->
                    <li class="text_item3">录播课，可回放</li>
                    <li class="text_item4">李珂</li>
                </div>
            </ul>
            <ul>
                <div>
                    <li class="text_item1">新质生产力下的人才创新能力培养</li>
                    <!-- <li class="text_item2">3月30日(周六) 14:00-16:00</li> -->
                    <li class="text_item3">录播课，可回放</li>
                    <li class="text_item4">林岳</li>
                </div>
            </ul>
            <ul>
                <div>
                    <li class="text_item1">劳模和工匠人才创新工作室建设与实践</li>
                    <!-- <li class="text_item2">3月31日(周日) 10:00-12:00</li> -->
                    <li class="text_item3">录播课，可回放</li>
                    <li class="text_item4">高凤林</li>
                </div>
            </ul>
            <ul>
                <div>
                    <li class="text_item1">劳模工匠个人成长与创新团队建设</li>
                    <!-- <li class="text_item2">3月31日(周日) 14:00-16:00</li> -->
                    <li class="text_item3">直播课，可回放</li>
                    <li class="text_item4">冯德伦</li>
                </div>
            </ul>
            <ul>
                <div>
                    <li class="text_item1">创新思维与创新方法</li>
                    <!-- <li class="text_item2">4月1日(周一) 19:00-20:00</li> -->
                    <li class="text_item3">录播课，可回放</li>
                    <li class="text_item4">张文海</li>
                </div>
            </ul>
            <ul>
                <div>
                    <li class="text_item1">高质量发展形势下的班组建设</li>
                    <!-- <li class="text_item2">4月1日(周一) 19:00-20:00</li> -->
                    <li class="text_item3">录播课，可回放</li>
                    <li class="text_item4">袁健松</li>
                </div>
            </ul>
            <ul>
                <div>
                    <li class="text_item1">劳模工匠胜任能力与成长规律</li>
                    <!-- <li class="text_item2">4月1日(周一) 19:00-20:00</li> -->
                    <li class="text_item3">录播课，可回放</li>
                    <li class="text_item4">欧阳袖</li>
                </div>
            </ul>
            <ul>
                <div>
                    <li class="text_item1">岗位创新成果及成果申报</li>
                    <!-- <li class="text_item2">4月1日(周一) 19:00-20:00</li> -->
                    <li class="text_item3">录播课，可回放</li>
                    <li class="text_item4">窦学伟</li>
                </div>
            </ul>
            <ul>
                <div>
                    <li class="text_item1">创新工作室场景与实践</li>
                    <!-- <li class="text_item2">4月2日(周二) 19:00-20:00</li> -->
                    <li class="text_item3">录播课，可回放</li>
                    <li class="text_item4">宁允展、郭锐、李文龙</li>
                </div>
            </ul>
            <ul>
                <div>
                    <li class="text_item1">领衔人分享沙龙</li>
                    <!-- <li class="text_item2">4月3日(周三) 19:00-20:00</li> -->
                    <li class="text_item3">录播课，可回放</li>
                    <li class="text_item4">欧阳袖、李小锋、缪建国</li>
                </div>
            </ul>
          </div>
        </div>
        <p class="tips_text">
            为保证课程学习内容的递进性和完整性，请各位学员按照课程表安排顺序听课。
        </p>
      </div>
      <div class="cont_item cont3">
        <div class="num_title">
          <span>三、结业证书</span>
        </div>
        <div class="cont_info_data">
          <p class="time_text">
            学员完成课程设置中所规定的所有学时方可申请结业证书；结业证书将按照学员反馈环节中所提供的邮箱发送电子版（PDF）。
          </p>
        </div>
      </div>
      <div class="cont_item cont4">
        <div class="num_title">
          <span>四、问题解答</span>
        </div>
        <div class="cont_info_data">
          <p class="time_text">
            在学习过程中，请大家相关遵守网络安全规定，课程播放相关问题，请联系技术支持：18101055181张老师。
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
export default {
  name: "student_notice",
  data() {
    return {};
  },

  mounted() {},
  methods: {},
};
</script>
  <style scoped lang="scss">
  *{
    font-family: PingFang SC, PingFang SC;
  }
.student_notice {
  background: #ffffff;
  padding-bottom: 134px;
  .title_cont_box{
    background: url('~@/assets/pc/pop/title_bgm.png') no-repeat;
    background-size: 100% 100%;
    height: 240px;
    p{
        text-align: center;
        color: #FFFFFF;
    }
    p.title_text{
        font-family: YouSheBiaoTiHei, YouSheBiaoTiHei;
        font-weight: 400;
        font-size: 56px;
        line-height: 66px;
        letter-spacing: 2px;
        text-align: center;
        padding-top: 63px;

    }
    p.tips_info{
        font-size: 20px;
        color: #FFFFFF;
        line-height: 22px;
        margin-top: 20px;
        font-family: PingFang SC, PingFang SC;
    }
  }

  .notice_cont_box{
    width: 1200px;
    margin: 60px auto 0;
    .cont_item{
        margin-bottom: 70px;
        .num_title{
            span{
                display: inline-block;
                height: 78px;
                background: url('~@/assets/pc/pop/text_bgm1.png') no-repeat;
                background-size: 100% 100%;      
                padding: 0 55px 0 38px;
                line-height: 78px;
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                font-size: 30px;
                color: #FFFFFF;
                position: relative;
                border-radius: 5px 0 0 0;
            }
        }
        
        .cont_info_data{
            background: #F7F9FF;
            border-radius: 0px 12px 12px 12px;
            border: 1px dotted #4A74DC;
            box-sizing: border-box;
            padding: 32px 30px;
            p.time_text{
                font-size: 18px;
                color: #333333;
            }
            p.tips{
                margin-top: 16px;
                font-size: 18px;
                color: #98A4A1;
            }
        }
    }
    .cont1{
        .cont_info_data{
            padding: 36px 30px;
        }
    }
    // 课程时间安排
    .cont2{
        .table_cont_list{
            background: #F7F9FF;
            border-radius: 0px 12px 12px 12px;
            border: 1px dotted #4A74DC;
            box-sizing: border-box;
            padding: 30px 0;
            .table_head{
                height: 55px;
                background: rgba(31, 82, 211, .5);
                border-radius: 8px 8px 8px 8px;
                margin: 0 30px;
                display: flex;
                align-items: center;
                li{
                    
                    line-height: 55px;
                    font-size: 18px;
                    color: #FFFFFF;
                }
                
            }
            li{
                border-right: 1px solid #DAE4FF;
                height: 100%;
                text-align: center;
            }
            li:last-child{
                border: none;
            }
            .text_item1{
                width: 36%;
            }
            .text_item2{
                width: 30%;
            }
            .text_item3{
                width: 20%;
            }
            .text_item4{
                width: 34%;
            }
            .cont_list{
                ul{
                    display: flex;
                    padding: 0 29px;
                    height: 70px;
                    div{
                        width: 100%;
                        display: flex;
                        border-bottom: 1px solid #DAE4FF;
                    }
                    li{
                        text-align: center;
                        font-size: 16px;
                        color: #3D3D3D;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                    }
                }
                ul:nth-child(2n+1){
                    background: rgba(31, 82, 211, .02);
                }
            }
        }
        .tips_text{
            font-size: 14px;
            color: #666666;   
            margin-top: 20px;  
        }
    }
  }
}
</style>